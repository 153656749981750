import React, { useContext, useState, ReactNode, FC } from 'react'
import { VerticalContext } from 'store/verticals/Context'
import Box from '@mui/system/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import InfoIcon from '@mui/icons-material/Info'
import { SxProps, Theme } from '@mui/material'
import Button from '@mui/material/Button'
import { overviewMapping } from './utils'
import { getAbbreviatedNumber } from 'components/Income/Income'
import TextTooltip from 'components/Tooltip'
import {
  Container,
  Logo,
  Title,
  Address,
  OverviewMapping,
  OverviewMappingLabel,
  OverviewMappingValue,
  TooltipText,
  DetailsButton,
} from './styles'
import Icon from 'components/Icon'
import OverviewDialog from './OverviewDialog'
import useFetchSvg from 'hooks/useFetchSvg'

interface IOverview {
  sxContainer?: SxProps<Theme>
  children?: ReactNode
}

const Overview: FC<IOverview> = ({ sxContainer = {}, children }) => {
  const { verticalEntity } = useContext(VerticalContext)

  const [open, setOpen] = useState(false)
  const [showFullText, setShowFullText] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleToggleText = () => {
    setShowFullText(!showFullText)
  }

  const aboutText = verticalEntity?.overviewDetails?.about || 'N/A'
  const truncatedText = aboutText.length > 256 ? `${aboutText.slice(0, 256)}...` : aboutText

  const svgBase64 = useFetchSvg(verticalEntity?.entityDetails?.image)

  return (
    <Box
      sx={[
        ...(Array.isArray(Container) ? Container : [Container]),
        ...(Array.isArray(sxContainer) ? sxContainer : [sxContainer]),
      ]}
    >
      <>
        <Stack flexDirection='row' alignItems='center'>
          {verticalEntity?.entityDetails?.image?.endsWith('.svg') ? (
            <Box
              component='img'
              src={svgBase64 || ''}
              alt={verticalEntity?.entityDetails?.name}
              sx={{ ...Logo, height: '80px', maxHeight: '80px' }}
              mr={2}
            />
          ) : (
            <Box
              component='img'
              src={verticalEntity?.entityDetails?.image}
              alt={verticalEntity?.entityDetails?.name}
              height={40}
              maxHeight={40}
              maxWidth={200}
              sx={{ ...Logo, height: '80px', maxHeight: '80px' }}
              mr={2}
            />
          )}
          <Stack sx={{ maxWidth: '75%', width: '100%' }}>
            <Typography sx={{ ...Title }}>{verticalEntity?.entityDetails?.name || 'N/A'}</Typography>
            <Stack flexDirection='row' alignItems='center' mt={{ md: 0, lg: 0, xl: 0.5 }}>
              <Typography sx={{ ...Address }}>
                {showFullText ? aboutText : truncatedText}
                {aboutText.length > 256 && (
                  <span style={{ cursor: 'pointer', color: 'blue', marginLeft: '5px' }} onClick={handleToggleText}>
                    {showFullText ? 'View Less' : 'View More'}
                  </span>
                )}
              </Typography>
            </Stack>
          </Stack>
          <Stack justifyContent='center' alignItems='center' flexDirection='row' sx={{ ml: 'auto' }}>
            <Button
              sx={DetailsButton}
              variant='contained'
              startIcon={<Icon name='notes' size='medium' />}
              onClick={handleClickOpen}
            >
              Company Details
            </Button>
            {children && <Stack sx={{ ml: 1 }}>{children}</Stack>}
          </Stack>
        </Stack>

        <OverviewDialog
          open={open}
          handleClose={handleClose}
          details={{
            specialities: verticalEntity?.specialities,
            siteId: verticalEntity?.siteId,
            firstTaxReturn: verticalEntity?.firstTaxReturn,
            lastTaxReturn: verticalEntity?.lastTaxReturn,
            taxOfficeCivil: verticalEntity?.taxOfficeCivil,
            taxCreditsAndIncentives: verticalEntity?.taxCreditsAndIncentives,
            socialLinks: verticalEntity?.contactInfo?.weblinks,
          }}
        />
      </>
      <Stack
        direction='row'
        alignItems='center'
        spacing={{ md: 1, lg: 1.5, xl: 2 }}
        mt={{ md: 3, lg: 3, xl: 3 }}
        sx={OverviewMapping}
        divider={<Divider orientation='vertical' flexItem sx={{ bgcolor: '#DAD8DF' }} />}
        justifyContent='space-evenly'
      >
        {overviewMapping.map(item => {
          const combinedData = {
            ...(verticalEntity?.entityDetails ? verticalEntity?.entityDetails : {}),
            ...(verticalEntity?.overviewDetails ? verticalEntity?.overviewDetails : {}),
            ...(verticalEntity?.aggregatedTotals ? verticalEntity?.aggregatedTotals : {}),
          }

          const value = combinedData[item.key]

          const formattedValue = (() => {
            if (Array.isArray(value) && value.every(item => typeof item === 'string')) {
              if (item.key === 'zones') return value.length.toString()

              return value.join(', ')
            }
            if (typeof value === 'number') {
              const formattedNumber = getAbbreviatedNumber(value)
              return `${formattedNumber.finalNumber}${formattedNumber.unit}`
            }
            if (value && typeof value === 'string') {
              return value
            }
            return 'N/A'
          })()
          return (
            <Stack key={item.key}>
              <Typography sx={OverviewMappingLabel}>{item.label}</Typography>
              <Stack flexDirection='row' alignItems='center'>
                <Typography sx={{ ...OverviewMappingValue }}>
                  {formattedValue.length >= 16 ? `${formattedValue.slice(0, 16)}...` : formattedValue}
                </Typography>
                {item.tooltipText && (
                  <TextTooltip
                    position='bottom'
                    html={<Typography sx={TooltipText}>{item.tooltipText}</Typography>}
                    theme='light'
                  >
                    <InfoIcon htmlColor='rgba(126, 139, 166, 1)' sx={{ width: '20px', height: '20px', ml: 1 }} />
                  </TextTooltip>
                )}
              </Stack>
            </Stack>
          )
        })}
      </Stack>
    </Box>
  )
}

export default Overview
