import { defineMessages } from 'react-intl'

const scope = 'app.components.SettingsModal'

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Settings',
  },
  labels: {
    selectedZone: {
      id: `${scope}.labels.selectedZone`,
      defaultMessage: 'Current Zone',
    },
  },
  actions: {
    close: {
      id: `${scope}.actions.close`,
      defaultMessage: 'Close',
    },
  },
})
